import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import ModalAlert from '../Modal/ModalAlert';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { baseUrl } from '../shared/Config';
import ReactCodeInput from 'react-code-input';
import { useDispatch, useSelector } from 'react-redux';
import { setData } from '../toolkit/reducer/GetData';

export default function VerifyLogin() {
  const [isVerifying, setIsVerifying] = useState(false);
  const [enterNumber, setEnterNumber] = useState('');
  const [alertMessage, setAlertMessage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false);

  const [code, setCode] = useState(null)
  const closeModal = () => {
    setModalVisible(false)
  }
  const { state } = useLocation();
  const { screenname, number, phone, accessToken } = state;
  const [loader, setLoader] = useState(false)


  const props = {
    inputStyle: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'white',
      color: '#000',
      border: '1px solid lightskyblue'
    },
    inputStyleInvalid: {
      fontFamily: 'monospace',
      margin: '4px',
      MozAppearance: 'textfield',
      width: '40px',
      borderRadius: '3px',
      fontSize: '14px',
      height: '26px',
      paddingLeft: '7px',
      backgroundColor: 'black',
      color: 'red',
      border: '1px solid red'
    }
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onConfirmOtp = () => {
    const _storeData = (Aptoken, usertoken, userId, specturmId, specturmToken, phoneno, userName, firstName) => {
      try {
        const jsonValue = JSON.stringify({ "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName });
        const userdata = { "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName }
        dispatch(setData(userdata))
        navigate("/patienthome")

      } catch (error) {
        alert("Something went wrong")
      }
    };
    if (number) {
      setLoader(true)

      setIsVerifying(true)
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "phone": number,
        "ipaddress": "192.168.1.1",
        "otp": code
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };


      fetch(`${baseUrl}/signin`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status == 1) {
            setLoader(false)

            setIsVerifying(false)
            if (result) {
              _storeData(result.Aptoken, result.token, result.userid, result.spectrumId, result.specturmToken, result.phone, result.name, result.firstName)

            }
          }
          else if (result.status == 0) {
            setLoader(false)
            setIsVerifying(false)
            setAlertMessage(result.message)
            setModalVisible(true)


          }
          else {
            setLoader(false)

            setIsVerifying(false)
            setAlertMessage(result.message)
            setModalVisible(true)

          }
        })
        .catch(error => {
          setIsVerifying(false)
        })
    }
    else {
      setLoader(false)

      setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
      setModalVisible(true)

    }

  }
  const onResendCode = () => {
    if (number) {
      setIsVerifying(true)
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({

        "phone": number,
        "ipaddress": "192.168.1.1"
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };


      fetch(`${baseUrl}/sendotp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status == 1) {
            setAlertMessage("Code has been sent")
            setModalVisible(true)
            setIsVerifying(false)          
          }
          else if (result.status == 0) {
            setIsVerifying(false)
            setAlertMessage(result.message)
            setModalVisible(true)
          }
          else {
            setIsVerifying(false)

            setAlertMessage(result.message)
            setModalVisible(true)

          }
        })
        .catch(error => {
          setIsVerifying(false)
        })
    }
    else {
      setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
      setModalVisible(true)
    }

  }



  return (
    <Container>
      <div>
        <div className='banner-image-about'>
          <div>
            <span className="tagline" style={{ backgroundColor: '#8bc63e', color: '#eaf5db', borderTopRightRadius: '30px' }}>Login Verification</span>

            <div className="tagline-para pt-2" style={{ color: '#333', textAlign: 'left' }}>
              Please enter a six digit verification code.

            </div >
            <div className="tagline-para">

            </div >

            <Container className='p-4'>
              <Row className="justify-content-md-center p-2">
                <Col xs lg="2">
                </Col>
                <Col md="auto">
                  <ReactCodeInput type='number' fields={6} {...props} onChange={text => setCode(text)} />
                  <button onClick={onResendCode} style={{ color: '#fff', fontSize: '14px', backgroundColor: '#1980AB', padding: '4px', borderRadius: '5px', borderColor: '#fff' }}>Resend Code</button>
                </Col>
                <Col xs lg="2">
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col xs lg="2">
                </Col>
                <Col xs lg="3">
                  <div class="sub-main">

                    {loader ? <Spinner animation="border" style={{ color: '#0072BC' }} /> : <button onClick={onConfirmOtp} class="button-three">Verify</button>}

                  </div>
                </Col>
                <Col xs lg="2">
                </Col>
              </Row>

            </Container>


          </div>



        </div>
      </div>
      <ModalAlert
        message={alertMessage}
        visible={modalVisible}
        onConfirm={closeModal}
      />
    </Container>)
}
