import React from 'react'
import Sider from '../components/Sider'
import Nav from '../Nav';
import Footer from '../Footer';
import './layoutStyle.css'

const Layout = ({ children }) => {
    return (

        <>
            <div class="flex-container">
                <Nav />
                <div class="content">
                    <Sider />
                    <div class="main-content"> {children} </div>
                </div>
                <Footer />
            </div>
        </>
    )
}

export default Layout