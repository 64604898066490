import React from 'react';
import Nav from './Nav';
import MainRoutes from './Routes'
import "./App.css";
import Footer from './Footer';




function App() {
  return (
  

  <MainRoutes />
   
  );
}

export default App;
