import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import InnerContent from "./components/InnerContent"

import ProtectedRoutes from "./components/ProtectedRoutes"
import PublicRoutes from "./components/PublicRoutes"
import PatientHome from "./screens/PatientHome"
import PatientVital from "./screens/PatientVital"
import Dashboard from "./screens/Dashboard"
import FamilyRegisterProfile from "./screens/FamilyRegisterProfile"
import RegisterProfile from "./screens/RegisterProfile"
import NotFound from "./screens/NotFound"
import VerifyLogin from "./screens/VerifyLogin"
import RegisterScreen from "./screens/RegisterScreen"
import Verify from "./screens/Verify"
import RegisterCompleted from "./screens/RegisterCompleted"
import InitalScreen from "./screens/InitalScreen"
import LoginScreen from "./screens/LoginScreen"
import SignUpScreen from "./screens/SignUpScreen"
import Layout from "./layout/Layout"

const MainRoutes = () => (
	<Routes>

		<Route path="/" element={<ProtectedRoutes />}>
			{/* <Route path="/" element={<InnerContent />}> */}
			<Route
				path="/"
				element={<Navigate to="/patienthome" replace />}
			/>
			<Route path="/patienthome" element={<Layout><PatientHome /></Layout>} />
			<Route path="/patientvital" element={<Layout><PatientVital /></Layout>} />

			<Route path="/regfamily" element={<Layout><FamilyRegisterProfile /></Layout>} />
			<Route path="/dashboard" element={<Layout><Dashboard /></Layout>} />
			<Route
				path="*"
				element={<Navigate to="/patienthome" replace />}
			/>
			{/* </Route> */}
		</Route>

		{/** Public Routes */}
		{/** Wrap all Route under PublicRoutes element */}

		<Route path="/" element={<PublicRoutes />}>
			{/* <Route path="/inital" element={<InitalScreen/>} /> */}

			<Route path="/login" element={<LoginScreen />} />
			<Route path="/verifylogin" element={<VerifyLogin />} />
			<Route path="/register" element={<SignUpScreen />} />
			<Route path="/verifyregister" element={<Verify />} />
			<Route path="/regprofile" element={<RegisterProfile />} />

			<Route path="/regcompleted" element={<RegisterCompleted />} />
			{/* <Route path="*" element={<NotFound/>} />   */}
			<Route
				path="*"
				element={<Navigate to="/login" replace />}
			/>
		</Route>

		{/** Permission denied route */}

	</Routes>
)

export default MainRoutes
