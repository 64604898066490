import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { Row, Col } from 'antd'
import backgroundImage from '../assets/Images/Background.jpg'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import {baseUrl} from '../shared/Config';


export default function Dashboard() {
  const navigate = useNavigate();
  // const { state } = useLocation();
  // const { userid, Aptoken } = state;
  const userval = JSON.parse(localStorage.getItem('user'))
  const [loader, setLoader] = useState(true)

  const [info, setInfo] = useState()


  const loadRecord = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "bearer " + userval?.Aptoken);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      "PatientUniqueID": userval?.specturmId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${baseUrl}/QuestionnaireWithPatient`, requestOptions)
      .then(response => response.text())

      .then(result => {
        setLoader(false)

        setInfo(JSON.parse(result))
      })
      .catch(error=>{
                
      });
  }
  useEffect(() => {
    loadRecord();
  }, [])

  useEffect(() => {
    let arr = info?.result


  }, [info])


  return (

    <div className='banner-image-dashboard'>
      <div style={{ color: '#333', textAlign: 'left' }}>
        <span className="tagline" style={{ backgroundColor: 'rgb(237 237 237 / 14%)', color: '#000' }}>History</span>
      </div >
      <div >
        {loader ? <div style={{ flex: '1 1 0', minHeight: 0, display: 'flex', justifyContent: 'center', margin: 'auto', paddingTop: '200px' }}>
          <Spinner animation="border" style={{ color: '#0072BC', justify: 'center' }} />
        </div> :<>
          <Row style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
            <Row style={{ backgroundColor: '#E4FFDE', margin: '0px', paddingTop: ' 10px', PaddingBottom: '10px' }}>
              <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500', paddingLeft: '20px', marginBottom: '10px' }}>Date </Col>
              <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500',  marginBottom: '10px' }}>Name</Col>
              <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500',  marginBottom: '10px' }}>Status</Col>

            </Row>
</Row>
            <div style={{
              height: '59vh',
              overflowY: "scroll"
            }}>
              <Row style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                {/* <Row style={{ backgroundColor: '#E4FFDE', margin: '0px', paddingTop: ' 10px', PaddingBottom: '10px' }}>
                  <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500', paddingLeft: '20px', marginBottom: '10px' }}>Date </Col>
                  <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500', paddingLeft: '4px', marginBottom: '10px' }}>Name</Col>
                  <Col span={8} style={{ color: '#000', height: '20px', fontSize: '16px', fontWeight: '500', paddingLeft: '4px', marginBottom: '10px' }}>Status</Col>

                </Row> */}

                {info?.result?.map((value, index) => (
                 
                  <Row style={{ backgroundColor: '#fff', margin: '0px', paddingTop: ' 10px', borderBottom: '1px solid #00000014' }} className="table-row">
                    <Col span={8} style={{ color: '#000', height: '20px', fontSize: '14px', paddingLeft: '20px', marginBottom: '10px' }}>{value?.insertedAt.toString().replaceAll('/', '-')} </Col>
                    <Col span={8} style={{ color: '#000', height: '20px', fontSize: '14px', paddingLeft:'4px', fontWeight: '400', marginBottom: '10px' }}>{value?.firstName} {value?.lastName}</Col>
                    <Col span={8} style={{ color: '#000', height: '20px', fontSize: '14px', paddingLeft:'10px', marginBottom: '10px' }}>Submitted Successfully</Col>

                  </Row>
                ))}

              </Row>

            </div>
            </>

        }
      </div>
    </div>


  )
}
