import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import './style.css';
import { Form, Row, Col, Button, Input, DatePicker, Select, Radio } from 'antd'
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import {baseUrl} from '../shared/Config';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    md: {
      span: 4
    },
    sm: {
      span: 4,
    },
  },
}


export default function FamilyRegisterProfile() {
  const [loader, setLoader] = useState(false)

  const [ethnicityLookupsData, setEthnicityLookupsData] = useState([]);
  const { state } = useLocation();
  const { number, Aptoken } = state;
  const [firstname, setFirstName] = useState(null)
  const [lastname, setLastName] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(null)
  const [dateval, setDatevalue] = useState(null)
  const [genderval, setGenderVal] = useState(null);
  const [selectedEthnicity, setSelectedEthnicity] = useState(null);
  const [relationArray, setrelationArray] = useState();
  const [relationval, setRelationval] = useState(null);
  const [ethinicityList, setEthincityList] = useState([])
  const arrArr = ethinicityList?.map(x => (x?.name));


  let responseArr = [];
  const [tokenforuser, setTokenForUser] = useState('')
  const getAccessToken = () => {
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ "ContactNumber": number });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    fetch(`${baseUrl}/GetToken`, requestOptions)
      .then(response => response.json())
      .then(result => {

        setTokenForUser(result.accessToken)

      })
      .catch(error=>{
                
      });

  }

  useEffect(() => {
    getAccessToken()
  }, [])

  const navigate = useNavigate();
  const getRelationLookUps = () => {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", "bearer " + Aptoken);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${baseUrl}/Relationships`, requestOptions)
      .then(response => response.json())
      .then(result => {
        setrelationArray(result.result?.map(x => ({ value: x.value, label: x.title })))

      })
      .catch(error=>{
                
      });

  }
  const getEthncityLookUps = () => {
    fetch(`${baseUrl}/Ethnicity`)
      .then(response => response.json())
      .then(result => {
        result.result.map((item, index) => {

          if (item.text == 'NZ Maori') {
            let obj = {
              "label": "NZ Māori",
              "value": item.value
            }
            responseArr.push(obj);
          }
          else if (item.text == 'Refused to answer') {
            let obj = {
              "label": "Prefer not to answer",
              "value": item.value
            }
            responseArr.push(obj);
          }
          else if (item.text == 'Cook Islands Maori') {
            let obj = {
              "label": "Cook Islands Māori",
              "value": item.value
            }
            responseArr.push(obj);
          }
          else {
            let obj = {
              "label": item.text,
              "value": item.value

            }
            responseArr.push(obj);

          }
        })
        setEthnicityLookupsData(responseArr)
      })
      .catch(error=>{
                
      });

  }
  useEffect(() => {
    getEthncityLookUps();
    getRelationLookUps();
  }, [])


  const onSubmitApi = () => {
    setLoader(true)
    if (firstname == null || lastname == null || genderval == null || selectedEthnicity == null) {
      setLoader(false)
    }
    else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", "bearer " + Aptoken);
      myHeaders.append("Content-Type", "application/json");
      let fullname = firstname + " " + lastname
      var raw = JSON.stringify({
        "patientUniqueID": "null",
        "token": tokenforuser,
        "firstName": firstname,
        "lastName": lastname,
        "dateOfBirth": moment(new Date(date)).format("YYYY-MM-DD") + 'T00:00:00+00:00',
        "relationshipID": relationval,
        "gender": genderval,
        "ethnicity": selectedEthnicity,
        "contactNumber": number,
        "vacinationStatus": "Deses 2",
        "livingAlone": "yes",
        "pregnant": "no",
        "address": {
          "fullAddress": "",
          "houseNO": "489",
          "streetAddress": "",
          "building": "",
          "suburbTownID": 0,
          "cityAreaID": 0,
          "postCodeID": 0,
          "dhbCode": "",
          "dhbName": ""
        }
      });
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      // console.log(moment(new Date(date)).format("YYYY-MM-DD") + 'T00:00:00+00:00')

      fetch(`${baseUrl}/Patient`, requestOptions)
        .then(response => response.text())
        .then(result => {
          let a = JSON.parse(result)

          if (a?.error == null) {
            let a = JSON.parse(result)
            navigate('/patientvital', {
              state: {
                userid: a?.id,
                username: fullname,
                status: "CheckAdd", age: moment(date).format("YYYY-MM-DD") + 'T00:00:00+00:00',
                ethinicity: arrArr
              }
            })
          }
          setLoader(false)

        })
        .catch(error=>{
                
        });
    }

  }
  const [form] = Form.useForm();

  return (
    <div style={{ flex: '1 1 0' }}>
      <div className="about">
        <div className="About-header">
          <div>
            <h3 style={{fontWeight:"700"}}>We need a few details on the person you are calling about.</h3>
          </div>
        </div>
        <div className="About-content">
          <Form
            style={{ width: "100%" }}
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={() => onSubmitApi()}
            scrollToFirstError
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="firstname"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: 'First name is required!',
                      whitespace: true,
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Enter first name"
                    className="custom-input"
                    onChange={(value) => {
                      setFirstName(value.target.value)
                    }} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="lastname"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: 'Last name is required!',
                      whitespace: true,
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    placeholder="Enter last name"
                    className="custom-input"
                    onChange={(value) => {
                      setLastName(value.target.value)
                    }} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  name="dateofbirth"
                  label="Date of Birth"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your date of birth!',
                    },
                  ]}
                  hasFeedback>
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    style={{ width: '100%' }}
                    className="custom-input pointer"
                    onChange={(date) => {
                      setDate(date)
                    }}
                    inputReadOnly
                  />
                </Form.Item>

              </Col>
              <Col span={12}>
                <Form.Item
                  name="ethnicity"
                  label="Ethnicity"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Ethnicity!',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    mode="multiple"
                    allowClear
                    maxTagCount='responsive'
                    options={ethnicityLookupsData}
                    placeholder="Select your ethnicity"
                    onChange={value => {
                      setSelectedEthnicity(value)

                    }} />
                </Form.Item>

              </Col>
            </Row>
            <Row gutter={16} style={{ marginBottom: "30px" }}>
              <Col span={12}>
                <Form.Item
                  name="relationship"
                  label="Relationship"
                  rules={[
                    {
                      required: true,
                      message: 'Please select your Relationship!',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    options={relationArray}
                    placeholder='Relationship'
                    onChange={value => {
                      setRelationval(value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: 'Select your Gender!',
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    placeholder="Select your gender"
                    onChange={(val) => {
                      setGenderVal(val)
                    }} >
                    <Option value="male">Male</Option>
                    <Option value="female">Female</Option>
                    <Option value="gender diverse">Gender Diverse</Option>
                    <Option value="prefer not to answer">Prefer Not To Answer</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "flex-end" }}>
              <Form.Item >
                <Button type="primary" htmlType="submit" loading={loader}>
                Submit
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
      </div>
    </div >



  )
}
