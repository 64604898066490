import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

var cryptojs = require("crypto-js");

export default function PatientVital() {

  const [loadUrl, setLoadUrl] = useState(null)

  const [user, setUser] = useState(false)
  const { state } = useLocation();
  const navigate = useNavigate();

  const { userid, username, age, status, ethinicity } = state;
  const ethCheck = (element) => element === 491;

  const userval = JSON.parse(localStorage.getItem('spectruminfo'))
  let ethnicityval = status == "CheckAdd" ? ethinicity.some(ethCheck) : userval?.ethnicity != null ? userval?.ethnicity.some(ethCheck) : true
  let halfurl = ethnicityval ? 'yes' : 'no'

  const item = JSON.parse(localStorage.getItem('user'))

  let cryptoJson = {
    'patientId': userid,
    'name': username,
    'token': item?.Aptoken,
    'specturemId': status == "CheckAdd" ? userid : item.specturmId,
    'spectureToken': item?.specturmToken,
    'age': age,
    'ethnicity': halfurl,
    'profileid': 179
  }

  let encJson = cryptojs.AES.encrypt(JSON.stringify(cryptoJson), 'my-secret@123').toString()
  let encData = cryptojs.enc.Base64.stringify(cryptojs.enc.Utf8.parse(encJson))


  if (status === "CheckAdd") {


    //const setUrl = 'https://bcms-healthline.web.app/?patient=' + encData
    const setUrl = 'https://bcms-healthlineprod.web.app/?patient=' + encData
    return (

      <div style={{ display: 'flex', flex: '1 1 0' }}>
        <iframe className='banner-image-question'
          src={setUrl}>
        </iframe>
      </div>
      )


  } else {
    
    //const setUrl = 'https://bcms-healthline.web.app/?patient=' + encData
    const setUrl = 'https://bcms-healthlineprod.web.app/?patient=' + encData

    return (

      <div style={{ display: 'flex', flex: '1 1 0' }}>
        {console.log(setUrl)}
        <iframe className='banner-image-question'
          src={setUrl}>
        </iframe>
      </div>)

  }

  return (
    <div>

    </div>
  )
}
