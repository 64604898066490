import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css'
import { Input, Button, message } from 'antd'
import VerificationModal from '../Modal/VerificationModal';
import { useDispatch } from 'react-redux';
import { setData } from '../toolkit/reducer/GetData';
import Nav from '../Nav';
import Footer from '../Footer';
import { baseUrl } from '../shared/Config';
export default function
  LoginScreen() {

  const [loader, setLoader] = useState(false)
  const [loaderOtp, setLoaderOtp] = useState(false)
  const [phonenumber, setPhoneNumber] = useState(null)
  const [isVerifying, setIsVerifying] = useState(false);
  const [enterNumber, setEnterNumber] = useState('');
  const [alertMessage, setAlertMessage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [verifyModal, setVerifyModal] = useState(false)
  const [number, setNumber] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const closeModal = (code) => {
    onConfirmOtp(code)
    setModalVisible(false)
  }
  const onConfirmOtp = (code) => {

    const _storeData = (Aptoken, usertoken, userId, specturmId, specturmToken, phoneno, userName, firstName) => {
      try {
        const jsonValue = JSON.stringify({ "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName });
        const userdata = { "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName }
        dispatch(setData(userdata))
        navigate("/patienthome")

      } catch (error) {
      }
    };
    if (number) {
      setLoaderOtp(true)
      setIsVerifying(true)
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "phone": number,
        "ipaddress": "192.168.1.1",
        "otp": code
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };


      fetch(`${baseUrl}/signin`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status == 1) {
            setIsVerifying(false)
            if (result) {
              _storeData(result.Aptoken, result.token, result.userid, result.spectrumId, result.specturmToken, result.phone, result.name, result.firstName)

            }
          }
          else if (result.status == 0) {
            setLoaderOtp(false)
            setIsVerifying(false)
            setAlertMessage(result.message)
            setModalVisible(true)


          }
          else {
            setLoaderOtp(false)
            setIsVerifying(false)

            setAlertMessage(result.message)
            setModalVisible(true)

          }
        })
        .catch(error => {
          setIsVerifying(false)
        })
    }
    else {
      setLoaderOtp(false)
      setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
      setModalVisible(true)

    }

  }

  useEffect(() => {
    setNumber("+64" + phonenumber)
  }, [phonenumber])

  const oNNextScreen = () => {
    if (number) {
      setLoader(true)
      setIsVerifying(true)
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({

        "phone": number,
        "ipaddress": "192.168.1.1"
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${baseUrl}/sendotp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.status == 1) {
            setIsVerifying(false)
            if (result) {
              message.success(result.message)
              setVerifyModal(true)
              setLoader(false)
            }
          }
          else if (result.status == 0) {
            if (result.message.includes('We have sent OTP on provided phone number if it is registered with us.')) {
              navigate('/register', {
                state: {
                  loginNumber: phonenumber
                }
              })
            }
            setIsVerifying(false)
            setLoader(false)
          }
          else {
            setLoader(false)
            setIsVerifying(false)
            message.success(result.message)
          }
        })
        .catch(error => {
          setIsVerifying(false)

        })
    }
    else {
      setLoader(false)
      setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
      setModalVisible(true)
    }

  }

  return (
    <div className='main-body'>
      <Nav />
      <span className="tagline" style={{ textAlign: 'center', backgroundColor: '#8bc63e', color: ' #262626', paddingLeft: '20px' }}>Request a callback</span>
      <div className="main-cont">
        <div className="internalheading">
          Health Information
        </div>
        <div className="content-text">
          <p> Healthline provides you with free health advice 24/7.<br />
            <div className='note'><span style={{ fontWeight: "700" }}>Please note:</span> If you have a medical emergency and you require urgent assistance, please call 111 immediately.
              A medical emergency includes chest pain or tightness, difficulty breathing, choking, severe bleeding or bleeding that won’t stop, sudden weakness or difficulty talking, fainting or unconsciousness.
            </div>
            If you or someone with you is feeling unwell, <span>and it's not an emergency,</span> then enter your phone number below. We will check that we have your number right by sending you a verification code for you to enter.</p>
          <p className="margin-bottom-20">  Then we are going to ask you a few questions about how we can help. </p>
          <label for='phonenumber' className="margin-bottom-20"> Enter your phone number and click “Next” to confirm the number. </label>
          <div className='input-area'>
            <div className='login-input mb-3' >
              <img
                src={require('../assets/Images/nz.png')}
                style={{ width: '50px', height: '50px' }}
                alt="NZ Flag" />
              <span ></span>
              <Input
                id='countrycode'
                type="text"
                value="+64"
                disabled={true}
                style={
                  {
                    color: '#333',
                    backgroundColor: '#fff',
                    padding: '3px 8px',
                    paddingBottom: '10px',
                    paddingTop: '10px',
                    borderColor: '#fff',
                    borderRadius: "0px",
                    width: "60px",
                  }
                } />
              <Input
                id='phonenumber'
                style={{ color: '#333', padding: '3px 5px', paddingTop: '5px', paddingBottom: '8px', borderColor: '#fff', borderRadius: "0px", MozAppearance: "textfield", WebkitAppearance: "none" }}
                type="number"
                onChange={(e) => {
                  if (e.target.value.charAt(0) === '0') {
                    let myString = e.target.value.slice(1);
                    setPhoneNumber(myString)

                  }
                  else setPhoneNumber(e.target.value)
                }
                }
                value={phonenumber}
              />
            </div>
            <label for="countrycode" className="margin-bottom-20 " style={{ fontSize: "17px" }}>
              If you are calling from international number, please call us on Healthline 0800 611 116
            </label>
            <Button
              type='submit'
              onClick={oNNextScreen}
              className="button-three pointer"
              loading={loader} >Next
            </Button>
          </div>
        </div>
      </div>
      <VerificationModal
        verifyModal={verifyModal}
        setVerifyModal={setVerifyModal}
        onConfirm={closeModal}
        codeVerification={oNNextScreen}
        loadingResend={loader}
        loading={loaderOtp}
      />
      <Footer />
    </div>
  )
}
