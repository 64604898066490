import React, { useState } from 'react'
import { Modal, Button, InputNumber } from 'antd'
import PinInput from 'react-pin-input';

function VerificationModal({ verifyModal, setVerifyModal, onConfirm, loading, codeVerification, loadingResend }) {

    const [code, setCode] = useState(null)

    return (
        <Modal
            width={"600px"}
            title="Phone number verification"
            open={verifyModal}
            onCancel={() => setVerifyModal(false)}
            confirmLoading={loading == false ? false : loading}
            okButtonProps={{ style: { display: 'none' } }}
            maskClosable = {false}
        >
            <div style={{ padding: "20px" , display:"flex", flexDirection:"column"}}>
                <h6 className='text-center'>We have sent a six-digit number to your phone.</h6>
                <h6 className='text-center mb-4'>Please enter the code below to confirm your number, then click verify.</h6>
                <div className="text-center">
                    <PinInput
                        length={6}
                        initialValue=""
                        onChange={text => setCode(text)}
                        type="numeric"
                        inputMode="number"
                        style={{ padding: '10px', fontSize: "22px" }}
                        inputStyle={{ borderColor: '#eaf5db', borderRadius: "5px", marginLeft: "10px" }}
                        inputFocusStyle={{ borderColor: 'blue' }}
                        onComplete={(value, index) => { }}
                        autoSelect={true}
                        regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        className="mobile-pinbox"
                    />
                </div>
                <Button onClick={() => onConfirm(code)} loading={loadingResend} style={{ backgroundColor: "#4096ff", color: "white", width: "80px", alignSelf: "flex-end" }}>Verify</Button>
            </div>
            <div className="resend-box">
                <p style={{ fontWeight: "700" }}>Didn't get the code?</p>
                <p>Click Resend and we will send it again. </p>
                <Button onClick={() => codeVerification()} loading={loadingResend} style={{ backgroundColor: "#4096ff", color: "white", width: "80px", alignSelf: "flex-end" }}>Resend</Button>
                {/* <p>Change your number: Click "Cancel" to go back to the previous screen and change your number</p> */}
            </div>
        </Modal>
    )
}

export default VerificationModal