import React from "react";
import { NavLink } from "react-router-dom";
import "./App.css";
import { useNavigate } from 'react-router-dom';
import Badge from "@material-ui/core/Badge";
import { Button } from 'antd'


function Nav() {
  const userval = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate();


  const goDashboard = () => {
    navigate('/dashboard', {
      state: {
        userid: userval?.specturmId,
        Aptoken: userval?.Aptoken
      }
    })
  }
  const onlogout = () => {
    localStorage.clear();
    navigate('/login')
  }
  const onNext = () => {
    navigate('/patienthome')
  }

  return (



    <div>
      <header className="Header_headerContainer countiescare-bg">
        <div className="Header_nav">
          <div><img className="Header_logo"
            src={require('./assets/Images/counties-logo.png')}
            alt="Ministry of health logo alongside healthline logo" /></div>
        </div>
      </header>
    </div>
  );
}

export default Nav;
