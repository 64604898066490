import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { setData } from '../toolkit/reducer/GetData';
import { useDispatch, useSelector } from 'react-redux';

export default function RegisterCompleted() {

  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { result } = state;
  if (result) {

    const _storeData = (Aptoken, usertoken, userId, specturmId, specturmToken, phoneno, userName, firstName) => {
      try {
        const userdata = { "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName }
        // dispatch(setData(userdata))
        //localStorage.setItem("user", JSON.stringify(jsonValue?.Aptoken))
        // navigate("/patienthome")


      } catch (error) {
        alert("Something went wrong")
      }
    };
    _storeData(result.Aptoken, result.token, result.userid, result.spectrumId, result.specturmToken, result.phone, result.name, result.firstName)

  }

  const oNNextScreen = () => {
    if (result) {
      const _storeData = (Aptoken, usertoken, userId, specturmId, specturmToken, phoneno, userName, firstName) => {
        try {

          const userdata = { "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName }
          dispatch(setData(userdata))

          navigate("/patienthome")

        } catch {
          alert("Something went wrong")        }
      };
      _storeData(result.Aptoken, result.token, result.userid, result.spectrumId, result.specturmToken, result.phone, result.name, result.firstName)


    }


  }
  return (

    <Container>
      <div>
        <div className='banner-image-about'>
          <div>
            <span className="tagline" style={{ backgroundColor: '#8bc63e', color: '#eaf5db', borderTopRightRadius: '30px' }}>Registration Completed</span>

            <div className="tagline-para pt-2" style={{ color: '#333', textAlign: 'left' }}>
              You have successfully submitted your details.


              On the next Screen we will start your login.

            </div >


          </div>
          <Row className="justify-content-md-center">
            <Col xs lg="2">
            </Col>
            <Col xs lg="3">
              <div class="sub-main">
                <button onClick={oNNextScreen} class="button-three">Login</button>

              </div>
            </Col>
            <Col xs lg="2">
            </Col>
          </Row>


        </div>
      </div>

    </Container>
  )
}
