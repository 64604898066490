import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { Form, Row, Col, Button, Input, DatePicker, Select, Radio, Modal, message } from 'antd'
import '../screens/style.css';
import Sider from '../components/Sider';
import ModalAlert from '../Modal/ModalAlert';
import styled from 'styled-components';
// import Form from 'react-bootstrap/Form';
import Multiselect from 'multiselect-react-dropdown';
// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import { setData } from '../toolkit/reducer/GetData';
import {baseUrl} from '../shared/Config'
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;
const formItemLayout = {
    labelCol: {
        xs: {
            span: 8,
        },
        md: {
            span: 8
        },
        sm: {
            span: 4,
        },
    },
}



function SignUpModal({ modalVisibility, setModalVisibility, token, otp, number }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [ethnicityLookupsData, setEthnicityLookupsData] = useState([]);
    const { state } = useLocation();
    const [firstname, setFirstName] = useState(null)
    const [lastname, setLastName] = useState(null)
    const [date, setDate] = useState(null)
    const [genderval, setGenderVal] = useState(null);
    const [selectedEthnicity, setSelectedEthnicity] = useState(null);
    const [registrationCompleted, setRegistrationCompleted] = useState(false)
    const [result, setResult] = useState(null)

    useEffect(() => {
        getEthncityLookUps();
    }, [])
    const getEthncityLookUps = () => {
        fetch(`${baseUrl}/Ethnicity`)
            .then(response => response.json())
            .then(result => {
                let responseArr = []
                result.result.map((item, index) => {

                    if (item.text == 'NZ Maori') {
                        let obj = {
                            "label": "NZ Māori",
                            "value": item.value
                        }
                        responseArr.push(obj);
                    }
                    else if (item.text == 'Refused to answer') {
                        let obj = {
                            "label": "Prefer not to answer",
                            "value": item.value
                        }
                        responseArr.push(obj);
                    }
                    else if (item.text == 'Cook Islands Maori') {
                        let obj = {
                            "label": "Cook Islands Māori",
                            "value": item.value
                        }
                        responseArr.push(obj);
                    }
                    else {
                        let obj = {
                            "label": item.text,
                            "value": item.value

                        }
                        responseArr.push(obj);

                    }
                })
                setEthnicityLookupsData(responseArr)
            })
            .catch(error=>{
                
            });

    }

    const RegisterProfile = () => {
        setLoader(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({

            "otp": otp,
            "patientUniqueID": "null",
            "token": token,
            "firstName": firstname,
            "lastName": lastname,
            "dateOfBirth":  moment(new Date(date)).format("YYYY-MM-DD") + 'T00:00:00+00:00',
            "relationshipID": 0,
            // "gender": maleGender ? "Male" : 'Female',
            "gender": genderval,

            "ethnicity": selectedEthnicity,
            "contactNumber": number,
            "vacinationStatus": "Deses 2",
            "livingAlone": "yes",
            "pregnant": "no",
            "email": "mailto:abc@gmail.com",
            // "password": password.value,
            "name": firstname + " " + lastname,
            "ipaddress": "192.167.0.1",
            "address": {
                "fullAddress": "",
                "houseNO": "489",
                "streetAddress": "",
                "building": "",
                "suburbTownID": 0,
                "cityAreaID": 0,
                "postCodeID": 0,
                "dhbCode": "",
                "dhbName": ""

            }


        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/signup`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 0) {
                    setLoader(false)
                    message.error(result.message)
                } else {
                    setRegistrationCompleted(true)
                    setResult(result)
                    setLoader(false)
                }

            })

            .catch(error =>{
                
            });
    }

    const navigateToHome = () => {
        if (result) {
            const _storeData = (Aptoken, usertoken, userId, specturmId, specturmToken, phoneno, userName, firstName) => {
                try {

                    const userdata = { "Aptoken": Aptoken, "token": usertoken, "userid": userId, "specturmId": specturmId, "specturmToken": specturmToken, "phoneno": phoneno, "name": userName, "firstName": firstName }
                    dispatch(setData(userdata))

                    navigate("/patienthome")

                } catch (error) {
                    alert("Something went wrong")                }
            };
            _storeData(result.Aptoken, result.token, result.userid, result.spectrumId, result.specturmToken, result.phone, result.name, result.firstName)
        }

    }
    const [form] = Form.useForm();


    return (
        <Modal
            width={"900px"}
            title={registrationCompleted ? "Details submitted" : "Please enter your details below"}
            open={modalVisibility}
            footer={false}
            // onOk={() => { registrationCompleted ? navigateToHome() : RegisterProfile() }}
            onCancel={() => setModalVisibility(false)}
            confirmLoading={loader}
            // okText={registrationCompleted ? "Next" : "Register"}
            maskClosable = {false}
            >
            <div style={{ padding: "50px" }}>
                {!registrationCompleted ?
                    <Form
                        style={{ width: "100%" }}
                        form={form}
                        {...formItemLayout}
                        name="register"
                        onFinish={() => { RegisterProfile() }}
                        scrollToFirstError
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="firstname"
                                    label="First Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'First name is required!',
                                            whitespace: true,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        placeholder="Enter first name"
                                        className="custom-input"
                                        onChange={(value) => {
                                            setFirstName(value.target.value)
                                        }} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="lastname"
                                    label="Last Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Last name is required!',
                                            whitespace: true,
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        placeholder="Enter last name"
                                        className="custom-input"
                                        onChange={(value) => {
                                            setLastName(value.target.value)
                                        }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    name="dateofbirth"
                                    label="Date of Birth"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your date of birth!',
                                        },
                                    ]}
                                    hasFeedback>
                                    <DatePicker
                                        format={"DD-MM-YYYY"}
                                        style={{ width: '100%' }}
                                        className="custom-input"
                                        onChange={(date) => {
                                            setDate(date)
                                        }}
                                        inputReadOnly />
                                                           

                                </Form.Item>

                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="ethnicity"
                                    label="Ethnicity"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select your Ethnicity!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Select
                                        mode="multiple"
                                        allowClear
                                        maxTagCount='responsive'
                                        options={ethnicityLookupsData}
                                        placeholder="Select your ethnicity"
                                        onChange={value => {
                                            setSelectedEthnicity(value)

                                        }} />
                                </Form.Item>

                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: "30px" }}>
                            <Col span={12}>
                                <Form.Item
                                    name="gender"
                                    label="Gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Select your Gender!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Select
                                        placeholder="Select your gender"
                                        onChange={(val) => {
                                            setGenderVal(val)
                                        }} >
                                        <Option value="male">Male</Option>
                                        <Option value="female">Female</Option>
                                        <Option value="gender diverse">Gender Diverse</Option>
                                        <Option value="prefer not to answer">Prefer Not To Answer</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loader}>
                                    Submit
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                    : <div style={{ display: 'flex', backgroundColor: '#EDFFE9', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: "2rem", padding: '50px', textAlign: 'center' }}>
                        <span style={{ marginBottom: '20px', fontSize: '20px', fontWeight: 'bold' }}>You have successfully submitted your details.</span>
                        <span style={{ fontSize: '20px' }}>Please click next to request a call back. </span>
                        <Button type='primary' style={{ width: "200px" }} onClick={()=>navigateToHome()}>Next</Button>
                    </div>
                }
            </div>
        </Modal>
    )
}

export default SignUpModal