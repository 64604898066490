import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import backgroundImage from '../assets/Images/Background.jpg'
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import next from '../assets/Images/next.png'
import Modal from 'react-bootstrap/Modal';
import ModalAlert from '../Modal/ModalAlert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Multiselect from 'multiselect-react-dropdown';
import check from '../assets/Images/check.png'
import uncheck from '../assets/Images/uncheck.png'
import './style.css';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';

import { baseUrl } from '../shared/Config';


export default function RegisterProfile() {
  const [ethnicityLookupsData, setEthnicityLookupsData] = useState([]);
  const [loader, setLoader] = useState(false)

  const [phonenumber, setPhoneNumber] = useState()
  const [isVerifying, setIsVerifying] = useState(false);
  const [enterNumber, setEnterNumber] = useState('');
  const [alertMessage, setAlertMessage] = useState(null)
  const [firstname, setFirstName] = useState(null)
  const [lastname, setLastName] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const [dateval, setDatevalue] = useState(null)
  const [maleGender, setMaleGender] = useState(false);
  const [femaleGender, setFemaleGender] = useState(false);

  const [genderval, setGenderVal] = useState(null);
  const [diverseGender, setDiverseGender] = useState(false);
  const [notAnsGender, setNotAnsGender] = useState(false);
  const [ethinicityList, setEthincityList] = useState([])
  const [startDate, setStartDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(null)
  const handleChange = (e) => {
    setIsOpen(!isOpen);
    setStartDate(e);
    setDate(e);
    setDatevalue(e)
  };
  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const arrArr = ethinicityList?.map(x => (x?.name));
  const arrArrID = ethinicityList?.map(x => (x?.id));

  const { state } = useLocation();
  const { number, token, otp } = state;



  const closeModal = () => {
    setModalVisible(false)
  }
  let responseArr = [];

  const navigate = useNavigate();
  const getEthncityLookUps = () => {

    fetch(`${baseUrl}/Ethnicity`)
      .then(response => response.json())
      .then(result => {
        result.result.map((item, index) => {

          if (item.text == 'NZ Maori') {
            let obj = {
              "name": "NZ Māori",
              "id": item.value
            }
            responseArr.push(obj);
          }
          else if (item.text == 'Refused to answer') {
            let obj = {
              "name": "Prefer not to answer",
              "id": item.value
            }
            responseArr.push(obj);
          }
          else if (item.text == 'Cook Islands Maori') {
            let obj = {
              "name": "Cook Islands Māori",
              "id": item.value
            }
            responseArr.push(obj);
          }
          else {
            let obj = {
              "name": item.text,
              "id": item.value

            }
            responseArr.push(obj);

          }
        })
        setEthnicityLookupsData(responseArr)
      })
      .catch(error=>{
                
      });

  }

  useEffect(() => {
    getEthncityLookUps();
  }, [])
  const RegisterProfile = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      "otp": otp,
      "patientUniqueID": "null",
      "token": token,
      "firstName": firstname,
      "lastName": lastname,
      "dateOfBirth":  moment(new Date(date)).format("YYYY-MM-DD") + 'T00:00:00+00:00',
      "relationshipID": 0,
      // "gender": maleGender ? "Male" : 'Female',
      "gender": genderval,

      "ethnicity": arrArrID,
      "contactNumber": number,
      "vacinationStatus": "Deses 2",
      "livingAlone": "yes",
      "pregnant": "no",
      "email": "mailto:abc@gmail.com",
      // "password": password.value,
      "name": firstname + " " + lastname,
      "ipaddress": "192.167.0.1",
      "address": {
        "fullAddress": "",
        "houseNO": "489",
        "streetAddress": "",
        "building": "",
        "suburbTownID": 0,
        "cityAreaID": 0,
        "postCodeID": 0,
        "dhbCode": "",
        "dhbName": ""

      }


    })

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${baseUrl}/signup`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 0) {
          setLoader(false)

          alert(result.message)
        } else {
          navigate('/regcompleted', {
            state: {
              result: result
            }
          })
        }

      })

      .catch(error => {
      });
  }
  const onSubmit = () => {
    if (firstname == null || lastname == null || genderval == null || ethinicityList == null) {
      setModalVisible(true)
    }
    else {
      setLoader(true)
      RegisterProfile();

    }

  }


  return (

    <Container>
      <div>
        <div className='banner-image-home' >
          <div>
            <span className="tagline" style={{ backgroundColor: '#8bc63e', color: '#eaf5db', borderTopRightRadius: '30px' }}>Add Member</span>

            <div className="tagline-para pt-2" style={{ color: '#333', textAlign: 'left' }}>
              Please enter information below.
            </div >


            <Container className='p-4'>
              <Form>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                  <Form.Label column sm="2" md="2" lg="2" style={{ color: '#333', textAlign: 'start', marginLeft: '10px' }}>
                    First Name
                  </Form.Label>
                  <Col sm="3" md="3" lg="3">
                    <Form.Control className='inputForm' type="text" placeholder="First Name" value={firstname} onChange={(text) => setFirstName(text.target.value)} />
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="2" md="2" lg="2" style={{ color: '#333', textAlign: 'start', marginLeft: '10px' }}>
                    Last Name
                  </Form.Label>
                  <Col sm="3" md="3" lg="3">
                    <Form.Control className='inputForm' type="text" placeholder="Last Name" value={lastname} onChange={(text) => setLastName(text.target.value)} />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="2" md="2" lg="2" style={{ color: '#333', textAlign: 'start', marginLeft: '10px' }}>
                    Select Date
                  </Form.Label>
                  <Col sm="3" md="3" lg="3">
                    <Button className='inputField btnFieldDate inputForm' onClick={handleClick} value={dateval ? moment(dateval).format("DD-MM-YYYY") : 'Date'} style={{ borderColor: '#fff', backgroundColor: '#fff', textAlign: 'left', color: dateval ? '#333' : '#C2C2C2' }} >{dateval ? moment(dateval).format("DD-MM-YYYY") : 'Date'}</Button>


                    {isOpen && (
                      <DatePicker selected={startDate} onChange={handleChange} inline showYearDropdown
                        yearDropdownItemNumber={115}
                        scrollableYearDropdown={true}
                        scrollableMonthDropdown />
                    )}        </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" >
                  <Form.Label column sm='2' style={{ color: '#333', textAlign: 'start', marginLeft: '10px' }}>
                    Gender
                  </Form.Label>
                  <Col sm={4} >
                    <Row>
                      <Col>

                        <div style={{ marginTop: '20px', textAlign: 'left' }} onClick={() => {
                          setFemaleGender(false)
                          setMaleGender(true)
                          setNotAnsGender(false)
                          setDiverseGender(false)
                          setGenderVal("Male")
                        }}>
                          {maleGender ? <img src={check} style={{ width: '30px' }} alt='Checked box'/> : <img src={uncheck} style={{ width: '30px' }} alt='Unchecked box' />} <span style={{ color: '#333', textAlign: 'left' }}>Male</span>

                        </div></Col></Row>

                    <div style={{ marginTop: '20px', textAlign: 'left' }} onClick={() => {
                      setFemaleGender(true)
                      setMaleGender(false)
                      setNotAnsGender(false)
                      setDiverseGender(false)
                      setGenderVal("Female")
                    }}>
                      {femaleGender ? <img src={check} style={{ width: '30px' }} alt='Checked box' /> : <img src={uncheck} style={{ width: '30px' }} alt='Unchecked box'/>} <span style={{ color: '#333', }}>Female</span>

                    </div>


                    <div style={{ marginTop: '20px', textAlign: 'left' }} onClick={() => {
                      setFemaleGender(false)
                      setMaleGender(false)
                      setNotAnsGender(false)
                      setDiverseGender(true)
                      setGenderVal("Gender Diverse")
                    }}>
                      {diverseGender ? <img src={check} style={{ width: '30px' }} alt='Checked box'/> : <img src={uncheck} style={{ width: '30px' }} alt='Unchecked box' />} <span style={{ color: '#333', }}>Gender Diverse</span>

                    </div>


                    <div style={{ marginTop: '20px', textAlign: 'left' }} onClick={() => {
                      setFemaleGender(false)
                      setMaleGender(false)
                      setNotAnsGender(true)
                      setDiverseGender(false)
                      setGenderVal("Prefer not to answer")
                    }}>
                      {notAnsGender ? <img src={check} style={{ width: '30px' }} alt='Checked box'/> : <img src={uncheck} style={{ width: '30px' }} alt='Unchecked box'/>} <span style={{ color: '#333', }}>Prefer not to Answer</span>

                    </div>

                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                  <Form.Label column sm="2" style={{ color: '#333', textAlign: 'start', marginLeft: '10px' }}>
                    Select Ethinicity
                  </Form.Label>
                  <Col sm="3">
                    <Multiselect placeholder='Ethincity' className='multiselectContainer optionContainer chips'
                      options={ethnicityLookupsData}
                      displayValue='name'
                      onSelect={(selectedList, selectedItem) => {
                        setEthincityList(selectedList)




                      }}
                    />          </Col>
                </Form.Group>
              </Form>

              <Row className="justify-content-md-center">
                <Col xs lg="2">
                </Col>
                <Col xs lg="3">
                  <div class="sub-main">
                    {loader ? <Spinner animation="border" style={{ color: '#0072BC' }} /> : <button onClick={onSubmit} class="button-three">Register</button>}

                  </div>
                </Col>
                <Col xs lg="2">
                </Col>
              </Row>
            </Container>


          </div>



        </div>
      </div>
      <ModalAlert
        message={"Please fill all fields"}
        visible={modalVisible}
        onConfirm={closeModal}
      />
    </Container>
  )
}
