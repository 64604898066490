import React,{ useState }from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import backgroundImage from '../assets/Images/Background.jpg'
import logo from '../assets/Images/logo.jpeg'
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import submit from '../assets/Images/submit.png'
import banner from '../assets/Images/HL_image2.jpg'
import Modal from 'react-bootstrap/Modal';
import ModalAlert from '../Modal/ModalAlert';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';

import {baseUrl} from '../shared/Config';

import ReactCodeInput from 'react-code-input';
import { useDispatch,useSelector } from 'react-redux';

export default function Verify() {
  const [isVerifying, setIsVerifying] = useState(false);
  const [enterNumber, setEnterNumber] = useState('');
  const [alertMessage,setAlertMessage] =useState(null)
  const [modalVisible,setModalVisible] =useState(false)
  const [loading, setLoading] = useState(false);

  const [code,setCode] = useState(null)
  const closeModal = ()=>{
      setModalVisible(false)
  }
  const {state} = useLocation();
  const { number,phone,accessToken } = state;



    const props = {
        inputStyle: {
          fontFamily: 'monospace',
          margin:  '4px',
          MozAppearance: 'textfield',
          width: '40px',
          borderRadius: '3px',
          fontSize: '14px',
          height: '26px',
          paddingLeft: '7px',
          backgroundColor: 'white',
          color: '#000',
          border: '1px solid lightskyblue'
        },
        inputStyleInvalid: {
          fontFamily: 'monospace',
          margin:  '4px',
          MozAppearance: 'textfield',
          width: '40px',
          borderRadius: '3px',
          fontSize: '14px',
          height: '26px',
          paddingLeft: '7px',
          backgroundColor: 'black',
          color: 'red',
          border: '1px solid red'
        }
      }

    const dispatch =useDispatch();
    const navigate = useNavigate();

    const codeVerification = (value) => {
      setIsVerifying(true)
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
          "ContactNumber": number,
          "Token": accessToken,
          "otp":code
      });

      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
      };

      fetch(`${baseUrl}/VerifyToken`, requestOptions)
          .then(response => response.json())
          .then(result => {
              if (result.status == true) {
                  setIsVerifying(false)
                      navigate ('/regprofile',{state:{
                        number:number,
                        token: accessToken,
                        otp: code
                      }})
                

                  

                 
              }
              else {
                  setIsVerifying(false)
              }
          })
          .catch(error=>{
                
          });
        }
  
    const RegisterProfileOtpVerification = () => {      
        setIsVerifying(true)
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          
            "phone":number,
            "otp": code
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/otpverification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    setLoading(false)
                    codeVerification(code)
                    navigate ('/regprofile',{state:{
                      number:number,
                      token: accessToken,
                      accessToken: accessToken,
                      otp: code
                    }})
                     
                } else {
                    setLoading(false)
                    setAlertMessage(result.message)
                    setModalVisible(true)
                                }

                setIsVerifying(false)

            })

            .catch(error=>{
                
            });
            setIsVerifying(false)
    }
    const onResendCode = ()=>{
      if (number) {
        setIsVerifying(true)
        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "ContactNumber": number,//number
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        fetch(`${baseUrl}/GetToken`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == true) {
                    sendOTP(result.accessToken)

                }
                else {

                    setIsVerifying(false)
                    setAlertMessage(result.message)
                    setModalVisible(true)
                }
            })
            .catch(error=>{
                
            });
    } else {

        setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
        setModalVisible(true)

    }
}
const sendOTP = (token) => {
    setIsVerifying(true)
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "phone": number,
        "ipaddress": "192.168.1.1",
        "accessToken": token
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    fetch(`${baseUrl}/sendotpforregistration`, requestOptions)
        .then(response => response.json())
        .then(result => {
            if (result.status == 1) {
              setAlertMessage("Code has been sent")
              setModalVisible(true)
                setIsVerifying(false)
              

            }
            if (result.status == 0) {
                setAlertMessage(result.message)
                setModalVisible(true)
                setIsVerifying(false)
            }
            else {
                setIsVerifying(false)
               setAlertMessage(result.message)
                setModalVisible(true)
            }
        })
        .catch(error => {

            alert("There was an error processing your request. Please try again later. If issues presists please contact support.")
            setIsVerifying(false)
        });



    }
  return (
    <Container>
    <div>
    <div  className='banner-image-about'>
    <div>
           <span className="tagline"style={{backgroundColor:'#8bc63e',color:'#eaf5db',borderTopRightRadius:'30px'}}>SignUp Verification</span>
           
           <div className="tagline-para pt-2" style={{color:'#333',textAlign:'left'}}>
           Please enter a six digit verification code.

           </div >
  
           <Container className='p-4'>
         <Row className="justify-content-md-center p-2">
           <Col xs lg="2">
           </Col>
           <Col md="auto">     
           <ReactCodeInput type='number' fields={6} {...props}  onChange={text => setCode(text)}/>
     <button onClick={onResendCode} style={{color:'#fff',fontSize:'14px',backgroundColor:'#0072BC',padding:'4px',borderRadius:'5px',borderColor:'#fff'}}>Resend Code</button>


           </Col>
           <Col xs lg="2">
          </Col>
         </Row>
         <Row className="justify-content-md-center">
         <Col xs lg="2">
           </Col>
           <Col xs lg="3">
           <div class="sub-main">
           {loading?<Spinner animation="border" style={{color:'#0072BC'}} />:   <button onClick={RegisterProfileOtpVerification} class="button-three">Verify</button>}
   
       </div>   
           </Col>
           <Col xs lg="2">
          </Col>
         </Row>
         
       </Container>
   
           
           </div>
          
       
          
         </div>
       </div>
       <ModalAlert
     message={alertMessage}
     visible={modalVisible}
     onConfirm={closeModal}
       />
       </Container>   )
}
