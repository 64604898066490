import React from "react";
import {
  CountiescareBox,
} from "./FooterStyles";

const Footer = () => {
  return (
    <CountiescareBox>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div>
          <img src={require('./assets/Images/HL_lockup.png')} width={130} alt="HL Lockup"/>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <img src={require('./assets/Images/Poweredby_text.png')} width={130} alt="Powered By"/>
          </div>
          <div>
            <img src={require('./assets/Images/WA_logo_horizontal.png')} width={240} alt="WA Logo"/>
          </div>
        </div>
      </div>
    </CountiescareBox>
  );
};
export default Footer;
