import React, { useEffect, useState } from 'react'
import { Button,message  } from 'antd'
import { useNavigate } from 'react-router-dom';
import check from '../assets/Images/check.png'
import uncheck from '../assets/Images/uncheck.png'
import submit from '../assets/Images/submit.png'
import { useDispatch } from 'react-redux';
import ModalAlert from '../Modal/ModalAlert';
import { setSpectrumInfo } from '../toolkit/reducer/Spectruminfo';
import Spinner from 'react-bootstrap/Spinner';
import Sider from '../components/Sider';

import {baseUrl} from '../shared/Config';

export default function PatientHomes() {

  const userval = JSON.parse(localStorage.getItem('user'))
  const [loader, setLoader] = useState(false)
  const [specloader, setSpecLoader] = useState(true)
  const [myself, setMySelf] = useState(false)
  const [someone, setSomeone] = useState(false)
  const [value, setValue] = useState(null)
  const [alertMessage, setAlertMessage] = useState(null)
  const [modalVisible, setModalVisible] = useState(false)
  const closeModal = () => {setModalVisible(false)}
  const [date, setDate] = useState()
  const [specInfo, setSpecInfo] = useState()
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const spectrumInfo = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "bearer " + userval?.Aptoken);
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({ "PatientUniqueID": userval?.specturmId })
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${baseUrl}/PatientWithPatient`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status == 1) {
          setSpecLoader(false)
          dispatch(setSpectrumInfo(result?.result))
          setSpecInfo(result)
          setDate(result?.result?.dateOfBirth)

        }
        else if (result.status== 401){
          localStorage.clear();
          navigate('/login')
        }
      })
      .catch(error=>{
        setSpecLoader(false)        
      });
  }
  useEffect(() => {
    if (userval?.Aptoken) {
      spectrumInfo()
    }
    else alert("NO DATA FROM Local Storage")

  }, [userval?.Aptoken])
  const onNextScreen = () => {

    if ((specInfo != null) && (value != null)) {
      setLoader(true)
      if (value == 'Myself') {
        navigate('/patientvital', {
          state: {
            userid: userval?.userid
            ,
            username: userval?.name,
            age: date, Aptoken: userval?.Aptoken, status: "unCheck", ethinicity: null
          }
        })


      }
      if (value == 'Someone') {
        navigate('/regfamily', {
          state: {
            userid: userval?.userid
            ,
            username: userval?.name,
            status: "CheckAdd",
            number: userval?.phoneno, Aptoken: userval?.Aptoken
          }
        })
      }
    }
    else {
      setLoader(false)
      message.error("Please select an option")
      setLoader(false)
    }

  }

  const goDashboard = () => {
    navigate('/dashboard', {
      state: {
        userid: userval?.specturmId,
        Aptoken: userval?.Aptoken
      }
    })
  }

  return (

    <div style={specloader ? { flex: '1 1 0', minHeight: 0, display : 'flex', justifyContent : 'center', margin : 'auto' } : { flex: '1 1 0', minHeight: 0 }}>
      {specloader ? <div style={{display:'flex', flexDirection:'column', alignItems:"center"}}><Spinner animation="border" style={{ color: '#0072BC', justify: 'center' }} /><p>Loading Data...</p></div> : <div className="about">
        <div className="About-header">
          <div style={{display:"flex" ,gap:"0.5rem"}}>
            <span style={{ fontSize:"24px" }}>Kia ora</span>
            <span style={{ fontWeight: "700", fontSize:"24px" , textTransform:"capitalize"}}>{userval?.firstName}</span>
          </div>
        </div>
        <div className="About-content">
          <div className="tagline-para pt-2" style={{ color: '#333', fontSize: '16px' }}>
            {/* Is that <span style={{textTransform:"capitalize", paddingLeft:"4px" ,paddingRight:"4px"}}> {userval?.firstName}</span>  on the Phone? */}
            Are you calling about yourself, or someone else?
          </div >
          <div className="tagline-para-home ">
            <div>
              <div onClick={() => {
                setValue('Myself')
                setMySelf(true)
                setSomeone(false)
              }} className="pointer">
                {myself ? <img src={check} style={{ width: '25px'}} alt="Checked box"/> : <img src={uncheck} style={{ width: '25px' }} alt="Unchecked box" />}<span style={{ color: '#333', paddingLeft: '20px', fontSize: "16px" }}>Self</span>

              </div>
              <div style={{ marginTop: '20px' }} onClick={() => {
                setValue('Someone')
                setMySelf(false)
                setSomeone(true)
              }} className="pointer">
                {someone ? <img src={check} style={{ width: '25px' }} alt="Checked box"/> : <img src={uncheck} style={{ width: '25px' }} alt="Unchecked box" />}<span style={{ color: '#333', paddingLeft: '20px', fontSize: "16px" }}>Someone else</span>

              </div>
            </div>
          </div >
        </div>


      
        <Button type='submit' className="button-three btn-Next" onClick={onNextScreen} loading={loader}>Next</Button>


      </div>}
      <ModalAlert
        message={alertMessage}
        visible={modalVisible}
        onConfirm={closeModal}
      />
    </div>
  )
}
