import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { Row, Col, Input, Button, message } from 'antd'
import VerificationModal from '../Modal/VerificationModal';
import SignUpModal from '../Modal/SignUpModal';
import { useLocation } from 'react-router-dom';
import Nav from '../Nav';
import Footer from '../Footer';
import { baseUrl } from '../shared/Config';
export default function SignUpScreen() {
    const { state } = useLocation();
    const [loader, setLoader] = useState(false)
    const [loaderOtp, setLoaderOtp] = useState(false)
    const [phonenumber, setPhoneNumber] = useState(state?.loginNumber)
    const [isVerifying, setIsVerifying] = useState(false);
    const [enteredCode, setEnteredCode] = useState('');
    const [token, setToken] = useState('');
    const [alertMessage, setAlertMessage] = useState(null)
    const [modalVisible, setModalVisible] = useState(false)
    const [verifyModal, setVerifyModal] = useState(false)
    const [pehliBar, setPehliBar] = useState(true)
    const [signUpModalVisibility, setSignUpModalVisibility] = useState(false)


    const closeModal = (code) => {
        setLoaderOtp(true)
        setEnteredCode(code)
        RegisterProfileOtpVerification(code)
    }

    const [number, setNumber] = useState();
    const navigate = useNavigate();


    useEffect(() => {
        setNumber("+64" + phonenumber)
        if (state?.loginNumber && pehliBar) {
            codeVerification();
        }
    }, [phonenumber, number])

    const RegisterProfileOtpVerification = (code) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({

            "phone": number,
            "otp": code
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/otpverification`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {

                    message.success("Code verified successfully")
                    setVerifyModal(false)
                    setSignUpModalVisibility(true)
                    setLoaderOtp(false)
                    // setLoading(false)
                    // codeVerification(code)
                    // navigate('/regprofile', {
                    //     state: {
                    //         number: number,
                    //         token: accessToken,
                    //         accessToken: accessToken,
                    //         otp: code
                    //     }
                    // })

                } else {
                    message.error("You have entered the incorrect OTP")
                    setLoaderOtp(false)
                }
            })

            .catch(error => {

            });
    }

    const codeVerification = () => {
        if (number) {
            setLoader(true)
            setIsVerifying(true)
            setPehliBar(false)
            var myHeaders = new Headers();

            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "ContactNumber": number,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch(`${baseUrl}/GetToken`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == true) {
                        sendOTP(result.accessToken)
                        setToken(result.accessToken)
                    }
                    else {
                        setLoader(false)
                        setIsVerifying(false)
                        setAlertMessage(result.message)
                        setModalVisible(true)
                    }
                })
                .catch(error => {

                });
        } else {

            setAlertMessage("Sorry the phone number you have entered isn't in the correct format. Please try again.")
            setModalVisible(true)
            setLoader(false)

        }
    }

    const sendOTP = (token) => {
        setIsVerifying(true)
        var myHeaders = new Headers();

        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "phone": number,
            "ipaddress": "192.168.1.1",
            "accessToken": token
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${baseUrl}/sendotpforregistration`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status == 1) {
                    setVerifyModal(true)
                    setIsVerifying(false)
                    setLoader(false)
                }
                if (result.status == 0) {
                    if (result.message == "Phone number already registered") {
                        message.error("This phone number is already registered")
                    } else {
                        message.error(result.message)
                    }
                    setAlertMessage(result.message)
                    setIsVerifying(false)
                    setLoader(false)
                }
                else {
                    setIsVerifying(false)
                    message.success(result.message)
                    setAlertMessage(result.message)
                    setLoader(false)
                }
            })
            .catch(error => {

                alert("There was an error processing your request. Please try again later. If issues presists please contact support.")
                setIsVerifying(false)
            });
    }
    return (
        <div className='main-body'>
            <Nav />
            <span className="tagline" style={{ textAlign: 'center', backgroundColor: '#8bc63e', color: ' #262626', paddingLeft: "20px" }}>Request a callback</span>
            <div className="main-cont">
                <div className="internalheading">
                    Health Information
                </div>
                <div className="content-text">
                    <p> Healthline provides you with free health advice 24/7.
                        <div className='note'><span style={{ fontWeight: "700" }}>Please note:</span> If you have a medical emergency and you require urgent assistance, please call 111 immediately.
                            A medical emergency includes chest pain or tightness, difficulty breathing, choking, severe bleeding or bleeding that won’t stop, sudden weakness or difficulty talking, fainting or unconsciousness.
                        </div>
                        If you or someone with you is feeling unwell, <span>and it's not an emergency,</span> then enter your phone number below. We will check that we have your number right by sending you a verification code for you to enter.</p>
                    <p className="margin-bottom-20">  Then we are going to ask you a few questions about how can we help. </p>
                    <label for='phone' className="margin-bottom-20"> Enter your phone number and click “Next” to confirm the number. </label>
                    <div className='input-area'>
                        <div className='login-input mb-3' >
                            <img src={require('../assets/Images/nz.png')} style={{ width: '50px', height: '50px' }} alt='NZ Flag' />
                            <Input
                                id='code'
                                type="text"
                                value="+64"
                                disabled={true}
                                style={{ color: '#333', backgroundColor: '#fff', padding: '3px 8px', paddingBottom: '10px', paddingTop: '10px', borderColor: '#fff', borderRadius: "0px", width: "60px", }} />
                            <Input
                                id='phone'
                                style={{ color: '#333', padding: '3px 5px', paddingTop: '5px', paddingBottom: '8px', borderColor: '#fff', borderRadius: "0px", MozAppearance: "textfield", WebkitAppearance: "none" }}
                                type="number"
                                onChange={(e) => {
                                    if (e.target.value.charAt(0) === '0') {
                                        let myString = e.target.value.slice(1);
                                        setPhoneNumber(myString)

                                    }
                                    else setPhoneNumber(e.target.value)
                                }
                                }
                                value={phonenumber}
                            />
                        </div>
                        <label for='code' className="margin-bottom-20" style={{ fontSize: "17px" }}> If you are caling from international number, please call us on Healthline 0800 611 116</label>
                        <Button type='submit' onClick={codeVerification} className="button-three pointer" loading={loader} >Next</Button>
                    </div>
                </div>
            </div>

            <VerificationModal
                codeVerification={codeVerification}
                verifyModal={verifyModal}
                setVerifyModal={setVerifyModal}
                onConfirm={closeModal}
                loading={loaderOtp}
                loadingResend={loader}
            />
            <SignUpModal
                modalVisibility={signUpModalVisibility}
                setModalVisibility={setSignUpModalVisibility}
                otp={enteredCode}
                number={number}
                token={token}
            />

            <Footer />
        </div>

    )


}