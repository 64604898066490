import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { HomeFilled, ClockCircleFilled, LogoutOutlined } from '@ant-design/icons'

function Sider() {
    const navigate = useNavigate()
    const onlogout = async () => {
        localStorage.clear();
        // navigate('/login', { replace: true })
    }
    return (
        <div className='sider'>
            <NavLink to={'/patienthome'}><span className='navbar-link'><HomeFilled />Home</span></NavLink>
            <NavLink to={'/dashboard'}><span className='navbar-link'><ClockCircleFilled />History</span></NavLink>
            <NavLink to={'/login'} onClick={() => onlogout()}><span className='navbar-link'><LogoutOutlined />Logout</span></NavLink>
        </div>
    )
}

export default Sider
